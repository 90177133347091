import axios from 'axios';
import { handleResponse, handleError } from './response';
import * as storageConstants from '../Constants/storageConstant';
import * as commonServices from './common';
import * as urlconstant from '../Constants/urlConstants';

const BASE_URL = urlconstant.BASE_URL;

export const getHeaders = async () => {
  const obj = await commonServices.getLocalData(storageConstants.BUSINESS);

  return {
    'Accept': 'application/json',
    'Authorization': commonServices.getLocalData(storageConstants.AUTH) !== null ? `Bearer ${commonServices.getLocalData(storageConstants.AUTH)}` : null,
    'business-id': obj?.id,
  };
};

/** @param {string} resource */
export const getAll = async (resource, model = '') => {
  let headers = await getHeaders();
  return axios
    .get(`${BASE_URL}${resource}${model}`, { headers })
    .then(handleResponse)
    .catch(handleError);
};

export const get = async (resource, id) => {
  let headers = await getHeaders();
  return axios
    .get(`${BASE_URL}${resource}${id}`, { headers })
    .then(handleResponse)
    .catch(handleError);
};

export const post = async (resource, model = {}) => {
  let headers = await getHeaders();
  return axios
    .post(`${BASE_URL}${resource}`, model, { headers })
    .then(handleResponse)
    .catch(handleError);
};

export const put = async (resource, model) => {
  const headers = await getHeaders();
  return axios
    .put(`${BASE_URL}${resource}`, model, { headers })
    .then(handleResponse)
    .catch(handleError);
};

export const deleteAPI = async (id) => {
  const headers = await getHeaders();
  return axios
    .delete(`${BASE_URL}${id}`, { headers })
    .then(handleResponse)
    .catch(handleError);
};
