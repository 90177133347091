import './App.css';
import { useState, useEffect, Suspense, lazy, } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { fetchMasterData, fetchSystemData, getLocalData } from './Services/common';
import { AUTH, MASTER_DATA, SYSTEM_DATA } from './Constants/storageConstant';
import { isNullOrEmpty } from './Utils/stringUtils';
import MainLoader from './Components/_widgets/Loader/MainLoader';
import ProtectedRoute from './Protected/Protected';
import "react-datepicker/dist/react-datepicker.css";
import NotFound from "./Components/NotFound/NotFound"
import PrivacyPolicy from './Components/Privacy/Privacy';

const Login = lazy(() => import('./Components/Login/Login'));
const LoginLayout = lazy(() => import('./Components/Layouts/LoginLayout'));
const ResetPassword = lazy(() => import('./Components/ResetPassword/ResetPassword'));
const HomeLayout = lazy(() => import('./Components/Layouts/HomeLayout'));
const Dashboard = lazy(() => import('./Components/Dashboard/Dashboard'));
const ChangePassword = lazy(() => import('./Components/ChangePassword/ChangePassword'));
const Users = lazy(() => import('./Components/Users/Users'));
const Clients = lazy(() => import('./Components/Clients/Clients'));
const Register = lazy(() => import('./Components/Register/Register'));
const Profile = lazy(() => import('./Components/Profile/Profile'));
const Booking = lazy(() => import('./Components/Booking/Booking'));
const BookingDetail = lazy(() => import('./Components/Booking/BookingDetail'));
const Packages = lazy(() => import('./Components/Packages/Packages'));
const Vehicles = lazy(() => import('./Components/Vehicles/Vehicles'));

function App() {
  const location = useLocation();

  const [mainLoading, setMainLoading] = useState(false);

  useEffect(() => {
    const data = getLocalData(MASTER_DATA);
    const sysData = getLocalData(SYSTEM_DATA);
    const auth = getLocalData(AUTH);

    if (isNullOrEmpty(data)) {
      fetchMasterData(setMainLoading);
    }

    if (auth && isNullOrEmpty(sysData)) {
      fetchSystemData(setMainLoading);
    }
  }, [location.pathname]);

  return (
    <div className="App">
      <ToastContainer />

      <Suspense fallback={<MainLoader />}>
        {mainLoading
          ? <MainLoader />
          : <Routes>
            <Route element={<LoginLayout />} >
              <Route exact path='/' element={<ProtectedRoute Component={<Login location={location} />} />} />
              <Route exact path='/register' element={<Register location={location} />} />
              <Route exact path='/reset-password' element={<ResetPassword location={location} />} />
              <Route exact path='/privacy' element={<PrivacyPolicy location={location} />} />
              <Route exact path="/*" element={<NotFound />} />
            </Route>

            <Route element={<HomeLayout />}>
              <Route exact path="/profile" element={<ProtectedRoute Component={<Profile />} />} />
              <Route exact path="/change-password" element={<ProtectedRoute Component={<ChangePassword />} />} />
              <Route exact path="/dashboard" element={<ProtectedRoute Component={<Dashboard />} />} />
              <Route exact path="/users" element={<ProtectedRoute Component={<Users />} />} />
              <Route exact path="/clients" element={<ProtectedRoute Component={<Clients />} />} />
              <Route exact path="/bookings" element={<ProtectedRoute Component={<Booking />} />} />
              <Route exact path="/bookings/:bookingId" element={<ProtectedRoute Component={<BookingDetail />} />} />
              <Route exact path="/vehicles" element={<ProtectedRoute Component={<Vehicles />} />} />
              <Route exact path="/packages" element={<ProtectedRoute Component={<Packages />} />} />
              {/* <Route exact path="/invoices" element={<ProtectedRoute Component={<Invoices />} />} />
                <Route exact path="/invoice-page" element={<ProtectedRoute Component={<InvoicePage />} />} /> */}
              {/* <Route exact path="/business-settings" element={<ProtectedRoute Component={<BusinessSettings />} />} /> */}
              {/* <Route exact path="/bank-details" element={<ProtectedRoute Component={<BankDetails />} />} /> */}
            </Route>
          </Routes>}
      </Suspense>
    </div>
  );
}

export default App;
