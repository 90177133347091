import { CLIENT_ADMIN, CLIENT_USER } from '../Constants/appConstants';
import * as storageConstants from '../Constants/storageConstant';
import { BROWSER_STORAGE_ERROR } from '../Constants/stringConstants';
import { capitalizeFirstLetter, isNullOrEmpty } from '../Utils/stringUtils';
import { warn } from '../Wrapper/toast/toast';
import * as apiHelper from "./ApiHelper";
import { formatValue } from 'react-currency-input-field';

const localStorageArray = [storageConstants.AUTH, storageConstants.PROFILE, storageConstants.MASTER_DATA, storageConstants.SYSTEM_DATA, storageConstants.USER_ROLE, storageConstants.STATUS, storageConstants.VEHICLES, storageConstants.USERS, storageConstants.CLIENT_ID];
const sessionStorageArray = [];

export function getLocalData(name) {
    try {
        if (sessionStorageArray.includes(name)) {
            return (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? sessionStorage.getItem(name) : JSON.parse(sessionStorage.getItem(name));
        }
        else if (localStorageArray.includes(name)) {
            return (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? localStorage.getItem(name) : JSON.parse(localStorage.getItem(name));
        }
        return null;
    } catch (e) {
        return null;
    }
}

export function storeLocalData(name, data) {
    try {
        if (sessionStorageArray.includes(name)) {
            (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? sessionStorage.setItem(name, data) : sessionStorage.setItem(name, JSON.stringify(data, (k, v) => v === undefined ? null : v));
        }
        else if (localStorageArray.includes(name)) {
            (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? localStorage.setItem(name, data) : localStorage.setItem(name, JSON.stringify(data, (k, v) => v === undefined ? null : v));
        }
        return true;
    } catch (e) {
        alert('Your web browser does not support storing settings locally. Some settings may not save or some features may not work properly for you.');
        return false;
    }
}

export function clearLocalStorageData() {
    try {
        localStorage.clear();
        return true;
    } catch (e) {
        return false;
    }
}

export const getMasterDataFromLocal = () => {
    return getLocalData(storageConstants.MASTER_DATA);
}

export const getSystemDataFromLocal = async () => {
    return getLocalData(storageConstants.SYSTEM_DATA);
}

export const fetchMasterData = (setLoading) => {
    setLoading && setLoading(true);
    apiHelper.masterData().then(response => {
        setLoading && setLoading(false);
        if (response?.isSuccess) {
            storeLocalData(storageConstants.MASTER_DATA, response?.data?.masterData);
            storeLocalData(storageConstants.STATUS, response?.data?.status);
        }
    });
}

export function isEmailValid(email) {
    const regex = /^(([A-Za-z0-9](?!.*\.{2})[A-Za-z0-9_\-\.]+[A-Za-z0-9])|([A-Za-z0-9]{1,60}))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/; /* eslint-disable-line */
    return regex.test(email);
}

export const preventPlusMinusInNumberInput = (e) => {
    // Prevent entry of minus sign (keycode 189) and plus sign (keycode 187)
    if (e.keyCode === 189 || e.keyCode === 109 || e.keyCode === 187 || e.keyCode === 107) {
        e.preventDefault();
    }
}

export function checkUrl(string) { // eslint-disable-next-line
    return /^(?:(https?|ftp):\/\/)?[^\s\/$?#].[^\s]*\.[a-zA-Z]{2,}.*$/i
        .test(string.includes("www.") ? string.replace("www.", "") : string);
}

export const removeCommaFromAmount = amt => amt ? (amt?.toString()?.includes(',') ? amt?.toString()?.replace(/[,\s]/g, '') : amt) : '';

export const formattedValue = (val, prefix, allowDecimals = true, currency = 'INR') => formatValue({
    value: `${val}`,
    groupSeparator: ',',
    decimalSeparator: '.',
    prefix: prefix ? (currency === 'INR' ? "₹" : "$") : "",
    intlConfig: prefix ? (currency === 'INR' ? { locale: 'en-IN', currency: 'INR' } : { locale: 'en-US', currency: 'USD' }) : null,
    decimalScale: (prefix || allowDecimals) ? 2 : null,
});

export function amountFormatter(amount, type = 'int', rounding = false) {
    if (isNaN(amount)) return amount;
    const a = isNullOrEmpty(amount) ? (type === 'int' ? 0 : 0.00) : amount;

    return type === 'float'
        ? a?.toLocaleString("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2, })
        : a?.toLocaleString("en-IN");
}

export const getCountryOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.countries) {
        return data?.countries?.map(c => ({
            label: c.value,
            value: c.key,
        }));
    } else {
        fetchSystemData();
        return [];
    }
}

export const getVendorOptions = () => {

    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data && Array.isArray(data.clients)) {
        return [...data?.clients?.map(c => ({
            label: c.name,
            value: c.id,
        }))];
    } else {
        return [];
    }
}

export const getPackageOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data && Array.isArray(data?.packages)) {
        return [...data?.packages?.map(p => ({
            label: p?.name,
            value: p?.id,
            fixedCharge: p?.flat_rate,
        }))];
    } else {
        return [];
    }
}

export const getPackageName = (id) => {
    const data = getPackageOptions();

    if (data && Array.isArray(data)) {
        return data?.filter(p => p?.value === parseInt(id))[0]?.label;
    } else {
        return '';
    }
}

export const getDriverOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data && Array.isArray(data.drivers)) {
        return data.drivers.map(c => ({
            label: c.name,
            value: c.id,
        }));
    } else {
        return [];
    }
}

export const getDriverName = (id) => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (id && Array.isArray(data?.drivers)) {
        return getDriverOptions()?.find(d => d?.value === id)?.label;
    } else {
        return '';
    }
}

export const getStatusOptions = () => {
    const data = getLocalData(storageConstants.STATUS);

    if (data && Array.isArray(data)) {
        return data?.map(s => ({
            label: s?.name,
            value: s?.value,
        }));
    } else {
        fetchMasterData();
        return [];
    }
}

export const getStatusNameById = (id) => {
    const foundStatus = getStatusOptions()?.filter(s => parseInt(s?.value) === parseInt(id));
    return foundStatus?.length > 0 ? foundStatus[0]?.label : '-';
}

export const getStateOptions = () => {
    return [{ label: "Haryana", value: 1 }, { label: "Punjab", value: 2 }, { label: "UP", value: 3 },]
    // const data = getLocalData(storageConstants.MASTER_DATA);

    // if (data) {
    //     return [...data?.states?.map(c => ({
    //         label: `${c.value}`,
    //         value: c.key,
    //     }))];
    // } else {
    //     fetchMasterData();
    // }
}

export const getRolesOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data && Array.isArray(data.roles)) {
        return data?.roles?.map(c => ({
            label: capitalizeFirstLetter(c.name.replace('_', ' ')),
            value: c.id,
        }));
    } else {
        return [];
    }
}

export const getVehicleOptions = () => {
    const data = getLocalData(storageConstants.VEHICLES);

    if (Array.isArray(data) && data?.length > 0) {
        return data;
    } else {
        return [];
    }
}

export const getVehicleTypeOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data) {
        return data?.vehicleType?.map(v => ({
            label: v.name,
            value: v.id,
        }));
    } else {
        return [];
    }
}

export const getVehicleNameById = (id) => {
    const vehicleoptions = getVehicleOptions()
    const vehicle = vehicleoptions?.find(v => v.value === id);
    return vehicle?.length > 0 ? vehicle[0]?.label : '-';
}

export const fetchSystemData = async (setLoading) => {
    setLoading && setLoading(true);
    await apiHelper.systemData().then((response) => {
        setLoading && setLoading(false);
        if (response?.isSuccess) {
            try {
                storeLocalData(storageConstants.SYSTEM_DATA, response?.data);
            } catch (e) {
                warn(BROWSER_STORAGE_ERROR);
            }
        }
    });
};

export const getIndustryOptions = () => {

    // const data = getLocalData(storageConstants.MASTER_DATA);

    // if (data) {
    //     return [...data?.industries?.map((i, index) => ({
    //         label: i.value,
    //         value: index + 1,
    //     }))];
    // } else {
    //     fetchMasterData();
    // }
}

export const getTaxTreatmentOptions = () => {
    // const data = getLocalData(storageConstants.MASTER_DATA);

    // if (data) {
    //     return [...data?.txt_treatment?.map(t => ({
    //         label: t.value,
    //         value: t.key,
    //     }))];
    // } else {
    //     fetchMasterData();
    // }
}

export const getPaymentStatusOptions = () => {
    // const data = getLocalData(storageConstants.MASTER_DATA);

    // if (data) {
    //     return [...data?.status?.map(s => ({
    //         label: s.value,
    //         value: s.key,
    //     }))];
    // } else {
    //     fetchMasterData();
    // }
}

export const getPaymentModeOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.payment_option) {
        const keys = Object.keys(data?.payment_option);
        const values = Object.values(data?.payment_option);

        return keys?.map((k) => ({
            label: values[keys.indexOf(k)],
            value: k,
        }))
    } else {
        return [];
    }
}

export const getGSTOptions = () => {
    return [{ label: '0%', value: 0 }, { label: '5%', value: 5 }, { label: '18%', value: 18 }];
}

export const setVehicleListInLocal = (list = []) => {
    if (Array.isArray(list) && list.length > 0) {
        const transformedArray = list.reduce((acc, v) => {
            if (v.status === 1) {
                acc.push({
                    label: v.number,
                    value: v.id,
                    driver: v.driver_id,
                });
            }
            return acc;
        }, []);

        storeLocalData(storageConstants.VEHICLES, transformedArray);
        return transformedArray;
    } else {
        return [];
    }
};

export const setUsersListInLocal = (list = [], updatedList = []) => {
    if (Array.isArray(updatedList) && updatedList.length === 0) {
        if (Array.isArray(list) && list?.length > 0) {
            const transformedArray = list?.map(u => ({
                label: u?.name,
                value: u?.id,
                mno: u?.mno,
            }));
            storeLocalData(storageConstants.USERS, transformedArray);
            return transformedArray;
        } else {
            return [];
        }
    } else if (Array.isArray(updatedList) && updatedList.length > 0) {
        storeLocalData(storageConstants.USERS, updatedList);
    }
}

export const isClientLogin = () => {
    const r = getLocalData(storageConstants.USER_ROLE);
    return r === CLIENT_ADMIN || r === CLIENT_USER;
}

