export const MASTER_DATA = "master_data";
export const SYSTEM_DATA = "system_data";
export const AUTH = 'auth';
export const LOGIN = 'login';
export const STATUS = 'status';
export const NAME = 'name';
export const VEHICLES = 'vehicles';
export const USERS = 'users';
export const PROFILE = 'profile';
export const USER_ROLE = 'user_role';
export const CLIENT_ID = 'c';
export const YEARS_TOTAL = 40;
export const RUPEE = "₹";
export const DOLLAR = "$";
export const CURRENCY_FORMAT = "en-IN";
export const SESSION = "session";
export const BUSINESS = "business";
export const BUSINESS_LIST = "businessList";
