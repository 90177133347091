import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as storageConstants from '../Constants/storageConstant';
import * as commonServices from '../Services/common';
import { isNullOrEmpty } from '../Utils/stringUtils';
import { warn } from '../Wrapper/toast/toast';
import { PAGE_NOT_ACCESSABLE } from '../Constants/stringConstants';

const ProtectedRoute = ({ Component }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isAuthenticated = !isNullOrEmpty(commonServices.getLocalData(storageConstants.AUTH));

    if (!isAuthenticated) {
      navigate("/");
    } else if (isAuthenticated) {
      if (location.pathname === "/") {
        navigate("/dashboard");
      } else if (commonServices.isClientLogin() && (location.pathname === "/clients" || location.pathname === "/vehicles")) {
        warn(PAGE_NOT_ACCESSABLE);
        navigate("/dashboard");
      }
    }
  }, [location.pathname]);

  return (
    <>
      {Component}
    </>
  );
}

export default ProtectedRoute;
